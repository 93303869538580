import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['rename'];

  static targets = ['name', 'contentField', 'selected', 'points', 'optionItem'];

  static values = { position: Number, gradingType: String, gapType: String };

  connect() {
    useDebounce(this, { wait: 250 });
  }

  resolve(e) {
    this.gapTypeValue = e.detail.value;

    if (!this.hasOptionItemTarget) return;
    if (this.gradingTypeValue === 'auto') return;

    if (this.gapTypeValue === 'dropdown') {
      this.dropdownPointsValues();
    } else {
      this.pointsTargets.forEach((input) => {
        this.setValue(input, 1);
      });
    }
  }

  dropdownPointsValues() {
    this.selectedTargets[0].checked = true;

    this.pointsTargets.slice(1).forEach((input, index) => {
      this.setValue(input, 0);
      this.selectedTargets[index].checked = false;
    });
  }

  rename() {
    if (this.hasContentFieldTarget) {
      this.nameTarget.value = `${this.positionValue}. ${this.contentFieldTarget.value}`;
    } else {
      this.nameTarget.value = '';
    }
  }

  update({ detail: { gradingType, gapType } }) {
    this.gradingTypeValue = gradingType;
    this.resolve({ detail: { value: gapType } });
  }

  setValue(input, value) {
    if (input.numeric) {
      input.numeric.setValue(value);
    } else {
      input.value = value;
    }
  }

  setPoints(e) {
    const currentParent = e.target.closest('[data-option-list-form-target="optionItem"]');

    this.pointsTargets.forEach((target) => {
      const pointsParent = target.closest('[data-option-list-form-target="optionItem"]');
      target.numeric.setValue((currentParent === pointsParent) ? 1 : 0);
    });
  }
}
