/* global I18n */
import { Controller } from '@hotwired/stimulus';
import { formatBytes } from '@modules/file-size';

export default class extends Controller {
  static targets = ['table', 'blankslate'];

  static values = { uploader: String };

  directUploadInitialize(event) {
    const { detail } = event;
    const { file } = detail;
    if (this.hasBlankslateTarget) this.blankslateTarget.remove();
    this.tableTarget.insertAdjacentHTML('afterbegin', `
      <div id="${file.name}" class="box mb-3">
        <div class="box p-3 d-flex flex-justify-between flex-items-center text-bold" style="background-color:#f7f7f7">
          <div class="flex-column3">${file.name}</div>
          <div class="flex-column2">${formatBytes(file.size)}</div>
          <div class="flex-column2">${I18n.t('js.page_collections.processing')}</div>
          <div class="flex-column2 progress-bar d-block my-3">
            <span class="progress" style="width: 0%" data-progress></span>
          </div>
          <div class="flex-column"></div>
        </div>
      </div>
    `);
  }

  directUploadProgress(event) {
    const { file, progress } = event.detail;
    const element = this.tableTarget.querySelector(`[id="${file.name}"]`);
    element.querySelector('[data-progress]').style.width = `${progress}%`;
  }

  directUploadError(event) {
    event.preventDefault();
    const { file, error } = event.detail;
    const element = this.tableTarget.querySelector(`[id="${file.name}"]`);
    element.querySelector('[data-progress]').innerText = error;
  }

  directUploadsEnd() {
    document.querySelector('[name="page_collection[file]"]').remove();
    document.getElementById('page_collection_file').disabled = false;
  }

  connect() {
    document.addEventListener('direct-upload:initialize', this.directUploadInitialize.bind(this));
    document.addEventListener('direct-upload:progress', this.directUploadProgress.bind(this));
    document.addEventListener('direct-upload:error', this.directUploadError.bind(this));
    document.addEventListener('direct-uploads:end', this.directUploadsEnd.bind(this));
  }

  disconnect() {
    document.removeEventListener('direct-upload:initialize', this.directUploadInitialize.bind(this));
    document.removeEventListener('direct-upload:progress', this.directUploadProgress.bind(this));
    document.removeEventListener('direct-upload:error', this.directUploadError.bind(this));
    document.removeEventListener('direct-uploads:end', this.directUploadsEnd.bind(this));
  }
}
