import onmount from 'onmount';

onmount('[data-js-multi-select-filter]', function () {
  const { attribute } = this.dataset;
  const filterItems = this.querySelectorAll('[data-js-multi-select-item]');
  const filterQuery = this.querySelector('[data-js-multi-select-query]');
  const filterInitialQuery = this.querySelector('[data-js-filter-initial-query]');

  const url = new URL(window.location.href);
  const urlQuery = url.searchParams.get('q') || '';

  function updateQuery() {
    const query = [];
    filterItems.forEach((item) => {
      if (item.hasAttribute('selected')) {
        query.push(item.getAttribute('data-js-multi-select-item'));
      }
    });
    if (query.length === 0) {
      filterQuery.setAttribute('data-query', '');
    } else {
      filterQuery.setAttribute('data-query', `${attribute}:${JSON.stringify(query)}`);
    }
  }

  function handleClick(e) {
    this.toggleAttribute('selected');
    this.classList.toggle('filter-item--selected');
    updateQuery();
    e.stopPropagation();
  }

  if (urlQuery.includes(`${attribute}:`)) {
    const regexInitialQuery = new RegExp(`${attribute}:(.*?)$|\\+`);
    const match = urlQuery.match(regexInitialQuery);
    filterInitialQuery.setAttribute('data-query', match[0]);
    filterItems.forEach((item) => {
      if (urlQuery.includes(item.getAttribute('data-js-multi-select-item'))) {
        item.toggleAttribute('selected');
        item.classList.toggle('filter-item--selected');
        updateQuery();
      }
    });
  }

  filterItems.forEach((item) => (item).addEventListener('click', handleClick));
});
